import styled from 'styled-components'
import { VehicleType } from '../vehicles/types'
import { formatDate, formatDateTime } from '../../utils/datetime'
import PdfPreview from './components/PdfPreview'
import PdfPage from './components/PdfPage'
import Heading from './components/Heading'
import VehicleImages from './components/VehicleImages'
import Header from '../../components/Header'
import VehicleInfo from '../vehicles/components/VehicleInfo'
import { isSeizureField } from '../vehicles/fields'
import env from "../../constants/env"

const S = {
  VehicleDetails: styled.div`
    margin-top: 60px;
    font-weight: bold;
  `,
  Note: styled.p`
    font-size: 18px;
  `,
}

const VehicleDetails = ({ vehicle }: { vehicle: VehicleType }) => (
  <S.VehicleDetails>
    <Header>Vehicle Details</Header>
    <br />
    Make: {vehicle.make || 'unkown'}
    <br />
    Model: {vehicle.model || 'unkown'}
    <br />
    VIN: {vehicle.vinNumber || 'unkown'}
    <br />
    Date of Theft Reported to Digitpol: {formatDate(vehicle.dateCreated) || 'unkown'}
    <br />
    Date of Theft: {formatDate(vehicle.dateOfTheft) || 'unkown'}
    <br />
    Location of Seizure: {vehicle.seizedLocationCity} {vehicle.seizedLocationStreet}
    <br />
    Seized Date: {formatDate(vehicle.datetimeSeized) || 'unkown'}
  </S.VehicleDetails>
)

interface ReportProps {
  vehicle: VehicleType
}

const SeizureReport = ({ vehicle }: ReportProps) => {
  const telematics = !vehicle.firstLocatedAt
    ? ''
    : `The vehicle was first located on the ${vehicle.firstLocatedDate || 'unknown date'} 
         at ${vehicle.firstLocatedAt || 'unknown location'}.`
  const body = env.isSixt
    ? `Regarding the vehicle identified by Vehicle Identification Number (VIN) ${
        vehicle.vinNumber
      }. Pursuant to a request from the legal owner of the vehicle, SIXT, and through an assignment, DIGITPOL has successfully traced and secured the aforementioned vehicle.\n\nThe vehicle was initially located on ${
        formatDateTime(vehicle.firstLocatedDate) || "'unknown'"
      } at coordinates ${vehicle.firstLocatedAt || "'unknown'"}. ${
        vehicle.datetimeSeized
          ? 'It was subsequently seized and taken into custody on ' +
            formatDateTime(vehicle.datetimeSeized)
          : ''
      }`
    : `
        With reference to the vehicle bearing the Vehicle Identity Number (VIN) of
        ${vehicle.vinNumber || 'unknown vin'}. Digitpol activated, based on a request from the ${
        vehicle.clientName || 'client'
      } 
        and an active SIS warrant, a tracking system in which enabled Digitpol to geolocate the 
        vehicle. ${telematics}
    `
  const containsLongNote = (vehicle.note || '').length > 600
  const vehicleInfo = (
    <VehicleInfo
      header="Seizure Info"
      vehicle={vehicle}
      fieldFilter={isSeizureField}
      compact
      filterEmptyFields
    />
  )
  return (
    <PdfPreview>
      <PdfPage>
        <Heading fileName="Seizure Report" vehicle={vehicle} />
        <br />
        <p>{body}</p>
        <VehicleImages vehicle={vehicle} />
      </PdfPage>

      <PdfPage>
        <VehicleDetails vehicle={vehicle} />

        <br />
        {vehicle.note && <b>Intelligence</b>}
        <S.Note>{vehicle.note}</S.Note>

        <br />
        {!containsLongNote && vehicleInfo}
      </PdfPage>

      {containsLongNote && <PdfPage>{vehicleInfo}</PdfPage>}
    </PdfPreview>
  )
}

export default SeizureReport
