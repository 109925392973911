import styled from 'styled-components'
import { Form } from 'react-bootstrap'
import { useState } from 'react'
import { Button, Stack, Box, Paper, Typography, Alert } from '@mui/material'
import Get from '../../../components/Get'

// todo move out of rdw feature

const S = {
    Title: styled.h4`
        font-weight: bold;
    `,
    Search: styled(Form.Control)`
        margin: 0;
        max-width: 300px;
    `,
    DataLine: styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 6px 12px;
        min-width: 0;
        & > div {
            min-width: 0;
            font-size: 13px;
        }
    `,
    GroupWrapper: styled.div`
        & > div:nth-child(2n) {
            background: #f5f5f5;
        }
    `,
    Header: styled.h1`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 16px 0;
        padding-bottom: 4px;
        border-bottom: 1px solid black;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
    `,
}

interface HelingResult {
    id: number
    type: string
    brand: string
    color: string
    matchType: string
    resultType: string
    uniqueNumber: string
    policeForceCode: string
    policeForceName: string
    motorSerialNumber: string
    registrationNumber: string
    productCategoryName: string
    chassisEngravementCode: string
    licensePlateRegistrationCode: string
}

interface HelingResponse {
    helingStatus: boolean
    helingRaw: HelingResult[]
    helingLastChecked: string
}

const HelingInfo = ({ data }: { data: HelingResponse }) => {
    if (!data?.helingRaw?.length) {
        return (
            <Alert severity="info" sx={{ mt: 2 }}>
                No StopHeling hits found for this vehicle
            </Alert>
        )
    }

    if (!data.helingStatus) {
        return (
            <Alert severity="success" sx={{ mt: 2 }}>
                No StopHeling hits found for this vehicle
            </Alert>
        )
    }

    return (
        <Paper sx={{ p: 2, mt: 2 }}>
            <Alert severity="error" sx={{ mb: 2 }}>
                StopHeling hit found - Vehicle is reported as {data.helingRaw[0].resultType.toLowerCase()}
            </Alert>
            
            <S.Header>StopHeling Details</S.Header>
            <S.GroupWrapper>
                {data.helingRaw.map((result, index) => (
                    <div key={index}>
                        <S.DataLine>
                            <div>Registration Number</div>
                            <div>{result.registrationNumber}</div>
                        </S.DataLine>
                        <S.DataLine>
                            <div>Police Force</div>
                            <div>{result.policeForceName}</div>
                        </S.DataLine>
                        <S.DataLine>
                            <div>Brand</div>
                            <div>{result.brand}</div>
                        </S.DataLine>
                        <S.DataLine>
                            <div>Model</div>
                            <div>{result.type}</div>
                        </S.DataLine>
                        <S.DataLine>
                            <div>Color</div>
                            <div>{result.color}</div>
                        </S.DataLine>
                        <S.DataLine>
                            <div>Category</div>
                            <div>{result.productCategoryName}</div>
                        </S.DataLine>
                        <S.DataLine>
                            <div>VIN</div>
                            <div>{result.chassisEngravementCode}</div>
                        </S.DataLine>
                        <S.DataLine>
                            <div>License Plate</div>
                            <div>{result.licensePlateRegistrationCode}</div>
                        </S.DataLine>
                        {result.motorSerialNumber && (
                            <S.DataLine>
                                <div>Motor Serial Number</div>
                                <div>{result.motorSerialNumber}</div>
                            </S.DataLine>
                        )}
                        <S.DataLine>
                            <div>Match Type</div>
                            <div>{result.matchType}</div>
                        </S.DataLine>
                        <S.DataLine>
                            <div>Status</div>
                            <div>{result.resultType}</div>
                        </S.DataLine>
                        <S.DataLine>
                            <div>Last Checked</div>
                            <div>{new Date(data.helingLastChecked).toLocaleString()}</div>
                        </S.DataLine>
                    </div>
                ))}
            </S.GroupWrapper>
        </Paper>
    )
}

const PlateSearch = () => {
    const [search, setSearch] = useState('')
    const [submitted, setSubmitted] = useState(false)

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        setSubmitted(true)
    }

    return (
        <Box sx={{ padding: { md: '20px 40px', xs: '16px 8px' } }}>
            <S.Title>StopHeling Search Plate</S.Title>

            <form onSubmit={handleSubmit}>
                <Stack flexDirection="row" alignItems="center" marginTop={2}>
                    <S.Search
                        placeholder="Search"
                        onChange={(e) => {
                            setSubmitted(false)
                            setSearch(e.target.value)
                        }}
                    />
                    <Button sx={{ ml: 2 }} variant="contained" type="submit">
                        Enter
                    </Button>
                </Stack>
            </form>

            {submitted && search && (
                <Box mt={3}>
                    <Get url={`/vehicles/search-by-plate/${search}/`}>
                        {(props) => <HelingInfo data={props.data} />}
                    </Get>
                </Box>
            )}
        </Box>
    )
}

export default PlateSearch
